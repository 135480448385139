<script setup lang="ts">
import { CampaignState } from "~/common/Enums";
import type { SuggestedCampaignDto } from "~~/_api_generated_";

const props = defineProps<{
  campaign: SuggestedCampaignDto;
  isHighestReward?: boolean;
  theme?: "dark" | "light" | "purple";
  signupBadgeTheme?: "dark" | "light";
  landingPageMode?: boolean;
}>();

const theme = computed(() => {
  return props.theme ?? "light";
});

const signupBadgeTheme = computed(() => {
  return props.signupBadgeTheme ?? "light";
});

const route = useRoute();
const routeCampaignId = computed(() => {
  return route.params.id as string;
});

const isEvaluating = computed(() => {
  if ("state" in props.campaign) {
    return props.campaign.state === CampaignState.EVALUATING;
  }
  return false;
});
</script>

<template>
  <div class="text-black/50 flex flex-wrap items-center gap-3">
    <BrandsIconAndName :theme="campaign?.advertiser?.theme" />

    <template v-if="!landingPageMode">
      <span class="w-1 h-1 rounded-full bg-black/50"></span>
      <div v-if="isEvaluating">Evaluating</div>
      <CampaignDateInfo
        v-else-if="campaign.id !== undefined"
        :campaign="campaign"
      />
      <span
        v-if="campaign.signupBonus > 0"
        class="w-1 h-1 rounded-full bg-black/50"
      ></span>
    </template>
    <div
      v-if="campaign.apyBonus > 0"
      class="flex items-center gap-1 px-[8px] py-[3px] rounded-full text-xs"
      :class="{
        'bg-black text-white': theme === 'light',
        'bg-[#CFCBFF] text-black': theme === 'dark' || theme === 'purple',
      }"
    >
      <IconsShieldIcon
        class="mr-[4px]"
        :class="{
          'text-white fill-white': theme === 'light',
          'text-black fill-black': theme === 'dark' || theme === 'purple',
        }"
      />
      Verified Members
    </div>
    <div
      v-if="campaign.signupBonus > 0 && !routeCampaignId"
      class="flex items-center group-hover:bg-grey-light gap-1 px-[8px] py-[3px] rounded-full text-black text-xs"
      :class="{
        'bg-gray-300': signupBadgeTheme === 'dark',
        'bg-gradient-to-b  from-white to-transparent border border-[#FFFFFFCC]':
          signupBadgeTheme === 'light',
        'group-hover:bg-none': signupBadgeTheme === 'light' && !landingPageMode,
      }"
    >
      {{ campaign.signupBonus }} {{ campaign.budgetCurrency }} Sign up Bonus
    </div>

    <span
      v-if="isHighestReward"
      class="w-1 h-1 rounded-full bg-black/50"
    ></span>
    <span v-if="isHighestReward" class="font-semibold text-sm text-purple">
      Highest reward
      <tippy to="parent">
        Because this post is eligible for more than one campaign, it will be
        automatically assigned to the campaign with the highest reward at the
        time of when campaigns end.
      </tippy>
    </span>
  </div>
</template>
