<script setup lang="ts">
import FireIcon from "~/components/Icons/FireIcon.vue";
import { computed } from "vue";
import { CampaignState } from "~/common/Enums";

import type { SuggestedCampaignDto } from "~~/_api_generated_";
const props = defineProps<{
  campaign: SuggestedCampaignDto;
}>();

const campaign = computed(() => {
  return props.campaign;
});

const startDate = computed(() => {
  return new Date(props.campaign.dateStart);
});

const endDate = computed(() => {
  return new Date(props.campaign.dateEnd);
});

const now = computed(() => new Date());

const isUrgent = computed(() => {
  if ("state" in campaign.value) {
    return campaign.value.state == CampaignState.ENDING;
  }
  return false;
});

const showStartsIn = computed(() => {
  return startDate.value > now.value;
});

const startsIn = computed(() => {
  const timeDiff = startDate.value.getTime() - now.value.getTime();
  const days = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
  const hours = Math.floor(
    (timeDiff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  );

  if (days > 0) {
    return `${days} day${days > 1 ? "s" : ""}`;
  } else if (hours > 0) {
    return `${hours} hour${hours > 1 ? "s" : ""}`;
  } else {
    return "less than an hour";
  }
});

const endsIn = computed(() => {
  const timeDiff = endDate.value.getTime() - now.value.getTime();
  const days = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
  const hours = Math.floor(
    (timeDiff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  );

  if (days > 0) {
    return `${days} day${days > 1 ? "s" : ""}`;
  } else if (hours > 0) {
    return `${hours} hour${hours > 1 ? "s" : ""}`;
  } else {
    return "less than an hour";
  }
});

const hasEnded = computed(() => {
  return endDate.value < now.value;
});

const startsOnNiceDate = computed(() => {
  return formatNiceDate(startDate.value);
});

const endsOnNiceDate = computed(() => {
  return formatNiceDate(endDate.value);
});
</script>

<template>
  <span v-if="showStartsIn" class="flex items-center gap-1">
    Starts in {{ startsIn }}
    <tippy to="parent"> Starts on {{ startsOnNiceDate }} </tippy>
  </span>
  <span
    v-else
    class="flex items-center flex-nowrap gap-1 text-nowrap"
    :class="{ 'text-red': isUrgent }"
  >
    <FireIcon v-if="isUrgent" />
    <span v-if="hasEnded">Ended</span>
    <span v-else>Ends in {{ endsIn }}</span>

    <tippy to="parent">
      <span v-if="hasEnded">Ended on {{ endsOnNiceDate }}</span>
      <span v-else>Ends on {{ endsOnNiceDate }}</span>
    </tippy>
  </span>
</template>
