<template>
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_604_3917)">
      <path
        d="M6 0.5L1.5 2.5V5.5C1.5 8.275 3.42 10.87 6 11.5C8.58 10.87 10.5 8.275 10.5 5.5V2.5L6 0.5Z"
        fill="inherit"
      />
    </g>
    <defs>
      <clipPath id="clip0_604_3917">
        <rect width="12" height="12" fill="inherit" />
      </clipPath>
    </defs>
  </svg>
</template>
