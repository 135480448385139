<script setup lang="ts"></script>

<template>
  <div class="flex items-center gap-2 shrink-0">
    <div class="w-6">
      <BrandsSwitchboardIcon :show-tooltip="false" />
    </div>
    Switchboard
    <tippy to="parent" :hide-on-click="false" interactive>
      <strong>Media sponsor:</strong> Switchboard Foundation<br />
      <strong>Country:</strong> Cayman Islands<br />
    </tippy>
  </div>
</template>
